import { createRouter, createWebHistory } from 'vue-router'
const WeatherTop = () => import('../views/WeatherTop.vue')
const WeatherFranceTop = () => import('../views/WeatherFranceTop.vue')
const WeatherFrance = () => import('../views/WeatherFrance.vue')
const WeatherJapanTop = () => import('../views/WeatherJapanTop.vue')
const WeatherJapan = () => import('../views/WeatherJapan.vue')
const WeatherAmericaTop = () => import('../views/WeatherAmericaTop.vue')
const WeatherAmerica = () => import('../views/WeatherAmerica.vue')
const PrivacyPolicy = () => import('../views/PrivacyPolicy.vue')
import WeatherDataFR from '../assets/WeatherDataFR.json'
import WeatherDataJP from '../assets/WeatherDataJP.json'
import WeatherDataUS from '../assets/WeatherDataUS.json'

const routes = [
  {
    path: '/',
    name: 'home',
    component: WeatherTop,
    meta: {
      title: '各地の年間の平均気温と降水量グラフ',
      metaTags: [
        {
          name: 'description',
          content: '各国の気象局の過去データを基にしたフランス、日本、アメリカの主要都市の年間の天気グラフです。旅行前に観光地の月別最高気温・最低気温・降水量・降水日数をグラフで確認！'
        },
        {
          property: 'og:description',
          content: '各国の気象局の過去データを基にしたフランス、日本、アメリカの主要都市の年間の天気グラフです。旅行前に観光地の月別最高気温・最低気温・降水量・降水日数をグラフで確認！'
        }
      ]
    }
  },
  {
    path: '/france/',
    name: 'franceTop',
    component: WeatherFranceTop,
    meta: {
      title: 'フランス各地の年間の平均気温と降水量グラフ',
      metaTags: [
        {
          name: 'description',
          content: 'フランス気象局の過去データを基にしたフランスの各都市の年間の天気グラフです。パリ、ベルサイユ、モンサンミッシェル、ストラスブール、コルマール、ナント、オルレアン、ボルドー、リヨン、トゥールーズ、マルセイユ、ニースなどフランスの主要観光都市の月別最高気温・最低気温・降水量・降水日数を確認！'
        },
        {
          property: 'og:description',
          content: 'フランス気象局の過去データを基にしたフランスの各都市の年間の天気グラフです。パリ、ベルサイユ、モンサンミッシェル、ストラスブール、コルマール、ナント、オルレアン、ボルドー、リヨン、トゥールーズ、マルセイユ、ニースなどフランスの主要観光都市の月別最高気温・最低気温・降水量・降水日数を確認！'
        }
      ]
    }
  },
  {
    path: '/france/:city/',
    name: 'france',
    component: WeatherFrance,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/japan/',
    name: 'japanTop',
    component: WeatherJapanTop,
    meta: {
      title: '日本各地の年間の平均気温と降水量グラフ',
      metaTags: [
        {
          name: 'description',
          content: '気象庁の過去データを基にした日本の各都市の年間の天気グラフです。札幌、東京、横浜、名古屋、京都、奈良、大阪、神戸、広島、那覇など日本の主要都市の月別最高気温・最低気温・降水量・降水日数を確認！'
        },
        {
          property: 'og:description',
          content: '気象庁の過去データを基にした日本の各都市の年間の天気グラフです。札幌、東京、横浜、名古屋、京都、奈良、大阪、神戸、広島、那覇など日本の主要都市の月別最高気温・最低気温・降水量・降水日数を確認！'
        }
      ]
    }
  },
  {
    path: '/japan/:city/',
    name: 'japan',
    component: WeatherJapan,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/usa/',
    name: 'americaTop',
    component: WeatherAmericaTop,
    meta: {
      title: 'アメリカ各都市の年間の平均気温と降水量グラフ',
      metaTags: [
        {
          name: 'description',
          content: 'アメリカ国立気象局の過去データを基にしたアメリカ50州の各都市の年間の天気グラフです。ニューヨーク、ホノルル、ロサンゼルス、ラスベガス、シカゴ、シアトル、ボストン、ポートランド、ヒューストン、デトロイトなどアメリカの主要都市の月別最高気温・最低気温・降水量・降水日数を確認！'
        },
        {
          property: 'og:description',
          content: 'アメリカ国立気象局の過去データを基にしたアメリカ50州の各都市の年間の天気グラフです。ニューヨーク、ホノルル、ロサンゼルス、ラスベガス、シカゴ、シアトル、ボストン、ポートランド、ヒューストン、デトロイトなどアメリカの主要都市の月別最高気温・最低気温・降水量・降水日数を確認！'
        }
      ]
    }
  },
  {
    path: '/usa/:city/',
    name: 'america',
    component: WeatherAmerica,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/privacy_policy',
    name: 'privacy',
    component: PrivacyPolicy,
    meta: {
      title: 'プライバシーポリシー',
      metaTags: [
        {
          name: 'description',
          content: 'プライバシーポリシー'
        },
        {
          property: 'og:description',
          content: 'プライバシーポリシー'
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
//  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

//  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
//  if(nearestWithTitle) {
//    document.title = nearestWithTitle.meta.title;
//  } else if(previousNearestWithMeta) {
//    document.title = previousNearestWithMeta.meta.title;
//  }

  // titleとdescriptionを設定
  var weatherData = ''
  var cityName = ''
  var description = ''
  if (Object.keys(to.params).length !== 0) {
    if (to.name === 'france') {
      weatherData = WeatherDataFR
      for (var i = 0; i < Object.keys(weatherData.cities).length; i++) {
        if (weatherData.cities[i].tag == to.params.city) {
          cityName = weatherData.cities[i].name
          description = 'フランス気象局の過去5年間(2020-2024年)のデータを基にしたフランスの都市' + cityName + 'の年間の天気グラフです。旅行前に' + cityName + 'の月別最高気温・最低気温・降水量・降水日数を確認！'
          document.title = cityName + 'の年間の平均気温と降水量グラフ'
        }
      }
    } else if (to.name === 'japan') {
      weatherData = WeatherDataJP
      for (var j = 0; j < Object.keys(weatherData.cities).length; j++) {
        if (weatherData.cities[j].tag == to.params.city) {
          cityName = weatherData.cities[j].name
          description = '気象庁の過去3年間(2020-2022年)のデータを基にした' + cityName + 'の年間の天気グラフです。' + cityName + 'の月別最高気温・最低気温・降水量・降水日数をグラフで確認！'
          document.title = cityName + 'の年間の平均気温と降水量グラフ'
        }
      }
    } else if (to.name === 'america') {
      weatherData = WeatherDataUS
      for (var k = 0; k < Object.keys(weatherData.cities).length; k++) {
        if (weatherData.cities[k].tag == to.params.city) {
          cityName = weatherData.cities[k].name
          description = 'アメリカ国立気象局の過去5年間(2020-2024年)のデータを基にした' + cityName + 'の年間の天気グラフです。' + cityName + 'の月別最高気温・最低気温・降水量・降水日数をグラフで確認！'
          document.title = cityName + 'の年間の平均気温と降水量グラフ'
        }
      }
    }
  } else {
    document.title = to.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      if (!tagDef[key]) {
        tag.setAttribute(key, description)
      } else {
        tag.setAttribute(key, tagDef[key])
      }
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')
    return tag
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag))
  next()
});

export default router
